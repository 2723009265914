<template>
  <div class="dashboard-editor-container">
    <!-- 条件 -->
    <div class="screen">
      <el-form
        :inline="true"
        ref="formInline"
        :model="formInline"
        class="demo-form-inline"
      >
        <el-form-item label="学院">
          <dept-select
            v-model="formInline.college"
            :defaultLabel="formInline.college"
            placeholder="请选择院系"
            valueField="no"
          />
        </el-form-item>
        <el-form-item label="专业">
          <major-select
            v-model="formInline.class"
            :defaultLabel="formInline.class"
            placeholder="请选择专业"
            :dept_no="formInline.college"
            valueField="no"
          />
        </el-form-item>
        <el-form-item label="年级">
          <dict-select
            v-model="formInline.grade"
            placeholder="请选择年级"
            type="stu_grade"
          />
        </el-form-item>
        <el-form-item label="性别" v-if="isScale">
          <el-select v-model="formInline.gender" placeholder="请选择性别">
            <el-option label="男" value="1"></el-option>
            <el-option label="女" value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="时间范围" v-if="isScale">
          <el-date-picker
            v-model="formInline.time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search"
            >搜索</el-button
          >
          <el-button @click="resetForm('formInline')" icon="el-icon-refresh"
            >重置</el-button
          >
          <el-button @click="isScale = !isScale" type="text"
            >{{ isScale ? "合并" : "展开" }}
            <i class="el-icon-arrow-down el-icon--right" v-if="!isScale"></i>
            <i class="el-icon-arrow-up el-icon--right" v-else></i>
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="box">
      <h4>数据快览</h4>
      <el-row>
        <!--   :span="24" -->
        <el-col
          v-for="(item, index) in number"
          :key="index"
          class="box-content"
          :xs="24"
          :sm="24"
          :md="12"
          :lg="12"
          :xl="12"
        >
          <div
            class="box-content-item"
            v-for="(option, count) in item"
            :key="count"
          >
            <div class="h2" :class="{ active: count == 0 }">
              {{ option.value }}
            </div>
            <span>{{ option.label }}</span>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-row :gutter="20">
      <el-col
        :xs="24"
        :sm="24"
        :md="12"
        :lg="12"
        :xl="12"
        v-for="item in dormRegiste"
        :key="item.title"
      >
        <div class="business">
          <h4>{{ item.title }}</h4>
          <div class="business-content">
            <div
              class="business-content-item"
              v-for="(option, count) in item.number"
              :key="count"
            >
              <div class="h2" :class="{ active2: count == 0 }">
                {{ option.value }}
              </div>
              <span>{{ option.label }}</span>
            </div>
          </div>
          <el-image
            class="business-img"
            fit="cover"
            :src="item.icon"
          ></el-image>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { listStuMajor } from "@/api/stu/stu_major";
export default {
  name: "Index",
  components: {},
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
      subtitle: process.env.VUE_APP_SUB_TITLE,
      // lineChartData: lineChartData.newVisitis,
      isScale: false,
      number: [
        [
          // { value: 18, label: "未归人数" },
          { value: 165, label: "晚归人数" },
          { value: 18, label: "请假人数" },
          { value: 98, label: "报修工单" },
        ],
        [
          // { value: 10, label: "今日未归人数" },
          { value: 23, label: "今日晚归人数" },
          { value: 3, label: "今日请假人数" },
          { value: 26, label: "今日报修工单" },
        ],
      ],
      dormRegiste: [
        {
          number: [
            { value: 18, label: "待处理" },
            { value: 165, label: "已处理" },
            { value: 0, label: "我提交的" },
            { value: 98, label: "抄送我的" },
          ],
          title: "调宿业务",
          icon: require("@/assets/dorm/tiao.png"),
        },
        {
          number: [
            { value: 12, label: "待处理" },
            { value: 1823, label: "已处理" },
            { value: 0, label: "我提交的" },
            { value: 1823, label: "抄送我的" },
          ],
          title: "退宿业务",
          icon: require("@/assets/dorm/tui.png"),
        },
        {
          number: [
            { value: 10, label: "待处理" },
            { value: 165, label: "已处理" },
            { value: 0, label: "我提交的" },
            { value: 98, label: "抄送我的" },
          ],
          title: "晚宿晚归",
          icon: require("@/assets/dorm/wan.png"),
        },
        {
          number: [
            { value: 3, label: "待处理" },
            { value: 165, label: "已处理" },
            { value: 0, label: "我提交的" },
            { value: 98, label: "抄送我的" },
          ],
          title: "违纪登记",
          icon: require("@/assets/dorm/wei.png"),
        },
        {
          number: [
            { value: 18, label: "待处理" },
            { value: 165, label: "已处理" },
            { value: 0, label: "我提交的" },
            { value: 98, label: "抄送我的" },
          ],
          title: "报修维修",
          icon: require("@/assets/dorm/bao.png"),
        },
        {
          number: [
            { value: 18, label: "待处理" },
            { value: 165, label: "已处理" },
            { value: 0, label: "我提交的" },
            { value: 98, label: "抄送我的" },
          ],
          title: "物品领用",
          icon: require("@/assets/dorm/ling.png"),
        },
      ],
      formInline: {
        college: "",
        class: "",
        grade: "",
        gender: "",
        time: "",
      },
    };
  },
  mounted() {
    this.listStuMajor();
  },
  methods: {
    onSubmit() {
      console.log("submit!");
    },
    resetForm(formName) {
      // this.$refs[formName].resetFields();
      for (let i in this.formInline) {
        this.formInline[i] = "";
      }
    },

    // 获取学院,专业
    listStuMajor() {
      let data = {
        pageNum: 1,
        pageSize: 999,
      };

      listStuMajor(data).then((res) => {
        console.log("学院,专业数据=>", res);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.screen {
  width: 100%;
  background: #fff;
  border-radius: 12px;
  padding: 20px;
}
.dashboard-editor-container {
  width: 100%;
  height: 100%;
  background: #f6f8f9 !important;
}
.active {
  color: red !important;
}
.active2 {
  color: #0099ff !important;
}
.box {
  background-color: #fff;
  border-radius: 12px;
  padding: 0px 3px 32px 20px;
  box-sizing: border-box;
  h4 {
    border-bottom: 1px solid #f9f9f9;
    line-height: 40px;
  }
  &-content {
    display: flex;
    justify-content: space-around;

    &:nth-child(1) {
      border-right: 1px solid #f9f9f9;
    }
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .h2 {
        font-size: 36px;
        font-weight: normal;
      }
      span {
        color: #8b8b8b;
      }
    }
  }
}

.business {
  background-color: #fff;
  border-radius: 12px;
  padding: 3px 3px 32px 20px;
  box-sizing: border-box;
  margin-top: 30px;
  position: relative;
  h4 {
    border-bottom: 1px solid #f9f9f9;
    line-height: 40px;
  }
  &-content {
    display: flex;
    justify-content: space-around;
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      .h2 {
        font-size: 36px;
        font-weight: normal;
      }
      span {
        color: #8b8b8b;
      }
    }
  }

  &-img {
    position: absolute;
    right: 0px;
    top: -20px;
    width: 90px;
    height: 80px;
  }
}
</style>
